import { keyframes } from "styled-components"

const CubeAnimate = keyframes`
    0% {
        transform: scale(0) translateY(0) rotate(0deg);
        opacity: 1;
    }
    100% {
        transform: scale(1.2) translateY(-100px) rotate(360deg);
        opacity: 0;
    }
`
const ArrowFooter = keyframes`
    0% {
        margin-left: -50px;
        opacity: 0.5;
    }
    100% {
        margin-left: 0px;
        opacity: 1;
    }
`

const ArrowFooterTop = keyframes`
    0% {
        margin-top: 30px;
        opacity: 0.5;
    }
    100% {
        margin-top: 0;
        opacity: 1;
    }
`

const InTouch1 = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
`

const InTouch2 = keyframes`
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(100%);
    }
`

const InTouch3 = keyframes`
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
`

const InTouch4 = keyframes`
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(-100%);
    }
`

const LoaderRipple = keyframes`
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
`

const BeTheNext = keyframes`
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1.1);
    }
    100% {  
        transform: scale(0.9);
    }
`

export {
  BeTheNext,
  LoaderRipple,
  CubeAnimate,
  ArrowFooter,
  ArrowFooterTop,
  InTouch1,
  InTouch2,
  InTouch3,
  InTouch4,
}

import styled from "styled-components"
import { Link } from "gatsby"
const Header = styled.header`
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 2;
  // display: flex;
  // -webkit-box-align: center;
  // align-items: center;

  // justify-content: space-between;
  // align-items: stretch;
  min-height: ${props => props.theme.headerHeight};
  background-color: ${props => props.theme.primaryColor};
`

const PrimaryMenu = styled.header`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-items: stretch;
  height: 100%;

  ${({ theme, showMobileMenu }) => theme.md`
    position: fixed;
    top: ${props => props.theme.headerHeight};
    bottom: 0;
    background: rgba(43, 29, 72, 0.9);
    left: 0;
    right:0;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: start;
    transition: transform 300ms;
    padding-top: 5px;
    // display: ${showMobileMenu ? "flex" : "none"};
    transform: scale(${showMobileMenu ? "1" : "0"});
  `}

  ${({ theme, showMobileMenu }) => theme.csm`
    padding-top: ${props => props.theme.headerHeight};
    transform: scale(${showMobileMenu ? "1" : "0"});
  `}
`

const LogoLink = styled(Link)`
  width: 80px;
  position: relative;
  z-index: 1;
  img {
    display: block;
    width: 100%;
  }
`

const NavLink = styled(Link)`
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  // flex: 1 1 0%;
  font-weight: 900;
  margin: 0 15px;
  text-decoration: none;
  position: relative;
  padding: 0 15px;
  transition: opacity 300ms;
  line-height: ${props => props.theme.headerHeight};
  color: ${props => props.theme.white};
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 5%;
    background: ${props => props.theme.quaternaryColor};
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: transform 300ms;
    transform-origin: right;
    transform: scaleX(0);
  }

  ${Header}:hover & {
    opacity: 0.3;
  }

  &:hover,
  &.current {
    opacity: 1 !important;
    :after {
      transform-origin: left;
      transform: scaleX(1);
    }
  }

  ${({ theme }) => theme.md`
  line-height: 30px;
  font-size: 16px;
  padding: 0 15px 10px;
  margin: 6px 0;
  font-weight: 600;
  letter-spacing: 1px;
  opacity: 1!important;
    &:after {
      height: 8%;
    }
  `}

  ${({ theme }) => theme.csm`
    margin: 12px 0;
  `}
`

const MenuMobile = styled.div`
  display: none;
  position: relative;
  z-index: 11;
  float: right;
  width: 35px;
  padding: 10px 0;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  .top,
  .bottom {
    position: absolute;
    left: 0;
    transition: all 250ms cubic-bezier(0.165, 0.84, 0.44, 1) 0ms;
  }
  .top {
    top: 0;
    transform: ${props =>
      props.showMobileMenu ? "translateY(7px) rotate(-45deg)" : "none"};
  }

  .center {
    transition: all 250ms cubic-bezier(0.165, 0.84, 0.44, 1) 0ms;
    transform: ${props =>
      props.showMobileMenu
        ? "translateX(0px) translateY(-3px) rotate(45deg)"
        : "none"};
  }

  .top,
  .center,
  .bottom {
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background: #fff;
    display: block;
  }

  .bottom {
    bottom: 0;
    display: ${props => (props.showMobileMenu ? "none" : "block")};
  }

  ${({ theme }) => theme.md`
    display: block;
  `}
`

export { MenuMobile, Header, NavLink, LogoLink, PrimaryMenu }

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import Theme from "../styles/Theme"
import media from "../styles/media"
import { LayoutContainer, Main } from "../styles/common"
import Header from "./header"
import Footer from "./footer"
import "./normalize.css"
import "../styles/icomoon/style.css"
import "../styles/style.css"
import "animate.css/animate.min.css"

class Layout extends React.Component {
  render() {
    const { location } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <ThemeProvider theme={{ ...Theme, ...media }}>
            <LayoutContainer>
              <Header siteTitle={data.site.siteMetadata.title} />
              <Main>{this.props.children}</Main>
              {/* {(!location || location !== "contact") && <Footer />} */}
              <Footer isContact={location && location === "contact"} />
            </LayoutContainer>
          </ThemeProvider>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import styled from "styled-components"
export const DevoxxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z*index: 1;
  background: white;
  align-items: start;
  justify-content: space-between;
`
export const DevoxxText = styled.div`
  box-sizing: border-box;
  width: 45%;
  padding-top: 40px;
  p {
    font-family: Lato;
    font-size: 16px;
    line-height: 26px;
    text-align: justify;
    margin: 0 0 15px;
    a {
      font-weight: 600;
    }

    span {
      font-weight: bold;
      color: ${props => props.theme.primaryLightColor};
    }
  }

  ${({ theme }) => theme.sm`
    width: 100%;
    order: 2;
  `}
`

export const DevoxxImgs = styled.div`
  width: 50%;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ theme }) => theme.sm`
    width: 100%;
    order: 1;
    margin-bottom: 30px;
  `}
`

export const DevoxxImg = styled.img`
  height: auto;
  margin-bottom: 4px;
  width: ${props => (props.full ? "100%" : "calc(50% - 2px)")};
`
export const Xmeetups = styled.div`
  display: flex;
  // align-items: start;
  flex-wrap: wrap;
`

export const Xmeetup = styled.div`
  width: 25%;
  box-sizing: border-box;
  transition: 300ms;
  // box-shadow: 0 0 10px rgba(43, 29, 72, 0.3);
  &:nth-child(even) {
    background-color: #e8e8e8;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
  p {
    margin: 20px 0 0;
    color: #707578;
    line-height: 150%;
    font-size: 14px;
    word-break: break-all;
    transition: 300ms;
  }

  a {
    color: #353c40;
    text-decoration: none;
  }

  h2 {
    transition: 300ms;
    margin: 0;
    font-size: 19px;
    line-height: 24px;
  }

  &:hover {
    background-color: ${props => props.theme.primaryColor};

    a,
    p {
      color: white;
    }
  }

  ${({ theme }) => theme.md`
    width: 50%;
  `}

  ${({ theme }) => theme.sm`
    width: 100%;
  `}
`

export const XmeetupDate = styled.div`
  color: white;
  position: absolute;
  left: 0;
  font-size: 13px;
  width: 70px;
  height: 70px;
  top: -70px;
  box-sizing: border-box;
  text-align: center;
  padding-top: 7px;
  line-height: 17px;
  word-break: break-all;
  background: ${props => props.theme.primaryColor};

  span {
    display: block;
  }

  .day {
    font-size: 15px;
    font-weight: 600;
  }
`

export const XmeetupDetails = styled.div`
  padding: 30px;
  position: relative;
`

export const CommunitySocialMedia = styled.div`
  display: flex;
  a {
    margin-right: 10px;
    text-decoration: none;
    border-radius: 50%;
    font-size: 0;
    > span {
      display: bloc;
      font-size: 26px;
      color: ${({ theme }) => theme.primaryLightColor};
    }
  }
`

const Theme = {
  primaryColor: "#2b1d48",
  primaryLightColor: "#a45fa7",
  secondaryColor: "#f07a22",
  tertiaryColor: "#6bcbd5",
  quaternaryColor: "#f05f53",
  darkColor: "#170f45",
  lightColor: "#ebedf0", // "#CADBC0",
  white: "#FFFFFF",
  headerHeight: "70px",
  footerHeight: "300px",
}

export default Theme

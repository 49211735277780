// import PropTypes from "prop-types"
import React, { Component } from "react"
import Helmet from "react-helmet"
import logo from "../images/logo-white-r.png"
import { Container } from "../styles/common"
// import Image from "./image"
import {
  PrimaryMenu,
  Header as HeaderContainer,
  NavLink,
  LogoLink,
  MenuMobile,
} from "../styles/header"

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showMobileMenu: false,
    }
  }

  toggleMobileMenu = () =>
    this.setState(prevState => ({ showMobileMenu: !prevState.showMobileMenu }))

  render() {
    const { showMobileMenu } = this.state
    return (
      <HeaderContainer>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Lato:300,400,700,800,900|Quicksand:300,400,500,700,800"
            rel="stylesheet"
          />
        </Helmet>
        <Container flex flexCenter headerHeight>
          <LogoLink to="/">
            <img src={logo} alt="xhub" style={{ width: "55%" }} />
          </LogoLink>

          <PrimaryMenu showMobileMenu={showMobileMenu}>
            {/* <NavLink activeClassName="current" to="/">
              Who we are
            </NavLink> */}
            <NavLink activeClassName="current" to="/what-we-do">
              What we do
            </NavLink>
            <NavLink activeClassName="current" to="/work">
              our work
            </NavLink>
            <NavLink activeClassName="current" to="https://life.x-hub.io/" target="_blank">
              xLife
            </NavLink>
            <NavLink activeClassName="current" to="/contact">
              get in touch
            </NavLink>
          </PrimaryMenu>
          <MenuMobile
            onClick={this.toggleMobileMenu}
            showMobileMenu={showMobileMenu}
          >
            <span className="top" />
            <span className="center" />
            <span className="bottom" />
          </MenuMobile>
        </Container>
      </HeaderContainer>
    )
  }
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header

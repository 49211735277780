import React from "react"
import { Container } from "../styles/common"
// import { findDOMNode } from "react-dom"

import {
  Footer as FooterContainer,
  H2,
  H1,
  CopyRight,
  Link,
  SocialIcons,
  ScrolToTopStyle,
  Arrow,
  FooterContent,
} from "../styles/footer"

const ScrolToTop = ({ children, ref }) => {
  // const el = findDOMNode("contactform")

  // console.log(el)

  return (
    <ScrolToTopStyle onClick={() => window.scrollTo(0, 350)}>
      {children}
    </ScrolToTopStyle>
  )
}

const Footer = ({ isContact = false }) => {
  const Comp = isContact ? ScrolToTop : Link
  return (
    <FooterContainer>
      <Container height="auto">
        <FooterContent>
          <SocialIcons>
            <a
              href="https://www.facebook.com/xhub.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon-facebook" />
            </a>
            <a
              href="https://twitter.com/xhubio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon-twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/xhub"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon-linkedin" />
            </a>
          </SocialIcons>
          <div>
            <Comp to="/contact">
              <H2>Want to discuss</H2>
              <H1>Let's work together.</H1>
              <Arrow>
                <span className="icon-right-arrow" />
              </Arrow>
            </Comp>
            <CopyRight>
              © XHUB. All rights reserved.
            </CopyRight>
          </div>
        </FooterContent>
      </Container>
    </FooterContainer>
  )
}

export default Footer

import { css } from "styled-components"

const sizes = {
  xs: "320px",
  csm: "480px",
  sm: "640px",
  md: "768px",
  tb: "1024px",
  lg: "1200px",
}

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}) {
      ${css(...args)};
    }
  `
  return acc
}, {})
